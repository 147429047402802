import './styles/styles.scss';
import 'animate.css/animate.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react'
import App from './App';
import {ScreenProvider} from "./Context/ScreenContext";

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');

if (container) {
    const root = createRoot(container); // createRoot(container!) if you use TypeScript

    root.render(
        <ScreenProvider>
            <App />
        </ScreenProvider>,
    );
}


