import React from "react";
import {Link} from "react-scroll"

const MenuItem = (props: any) => {
    return (
        <Link
            className="nav-link"
            activeClass="active"
            to={props.sectionId}
            spy={true}
            smooth="easeInOut"
            offset={-70}
            duration={100}
            style={{cursor: "pointer"}}
        >
            { props.sectionLabel || props.children }
        </Link>
    );
}

export default MenuItem