import styled from "styled-components";

const Section = styled.section`
  position: relative;
  min-height: 196px;
  height: 650px;
  max-height: 650px;
  display: block;
  z-index: 1000;
`

const HeroWrapper = styled.div`
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 200;
      width: 100%;
      height: 100%;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      text-align: center;
`

const StyledIFrame = styled.iframe`
  position: absolute;
  width: 190vh;
  height: 57.5vw;
  min-height: 100%;
  min-width: 100%;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
`

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #f7f7f7;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  z-index: 1;
  overflow: hidden;
`

const VideoPlaceholderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const StyledIFrameContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 5;
  background-color: #000;
  transition: opacity 1s linear;
`

const TransparentHeaderContainer = styled.div`
  position: absolute;
  top: auto;
  width: 100%;
  color: inherit;
  background-color: transparent;
`

export {
    Section,
    HeroWrapper,
    TransparentHeaderContainer,
    VideoContainer,
    StyledIFrame,
    StyledIFrameContainer,
    VideoPlaceholderContainer
}