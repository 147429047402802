import React, {useState} from 'react'
import Box from '../../../Generic/Box'
import SoundCloudPlayer from './SoundCloudPlayer'
import Form from 'react-bootstrap/Form';
import {StyledSectionHeader, StyledSectionMarker} from "../../../../styles/Styled/StyledGeneric";
import {SUBSCRIBE_FORM_ACTION} from "../../../../Constants";
import SubscribeForm from "./Subscribe/SubscribeForm";
import {
    StyledSubscribeButton,
    StyledSubscribeContainer,
    StyledListenHeaderContainer
} from "../../../../styles/Styled/StyledListen";
import {Animated} from "react-animated-css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

const Listen = () => {
    const [showSubscribeContainer, setShowSubscribeContainer] = useState(false)
    const [show, setShow] = useState(false)


    const handleClose = () => {
        setShow(false)
        setTimeout(() => {
            setShowSubscribeContainer(false)
        }, 250)
    }
    const handleShow = () => {
        setShow(true)
    }

    return (
        <>
            <StyledSectionMarker id="listen"/>
            <Box>
                <StyledListenHeaderContainer>
                    <Row>
                        <StyledSectionHeader className="card-text">
                            <strong> LISTEN </strong>
                        </StyledSectionHeader>
                    </Row>
                    <Row>
                        <h5>
                            Subscribe to get free music and updates from ill.Gates
                        </h5>
                    </Row>
                </StyledListenHeaderContainer>
                <StyledSubscribeContainer show={!show}>
                    <Container>
                        <Row className={"justify-content-md-center"} style={{position: "relative", zIndex: 1}}>
                            <Col md={2}>
                                <Animated animationIn={"fadeInDown"}
                                          animationOut={"fadeOutUp"}
                                          isVisible={!show}
                                >
                                    <StyledSubscribeButton show={`${!show}`}
                                                           addmargin={`${false}`}
                                                           onClick={() => {
                                                               setShowSubscribeContainer(true)
                                                               setTimeout(() => {
                                                                   handleShow()
                                                               }, 1)
                                                           }}
                                    >
                                        Subscribe
                                    </StyledSubscribeButton>
                                </Animated>
                            </Col>
                        </Row>
                    </Container>
                </StyledSubscribeContainer>

                {showSubscribeContainer &&
                    <StyledSubscribeContainer show={show}>
                        <Animated animationIn={"fadeInDown"}
                                  animationOut={"fadeOutUp"}
                                  isVisible={show}
                        >
                            <Form target="_blank"
                                  action={SUBSCRIBE_FORM_ACTION}
                                  method="post"
                                  acceptCharset="UTF-8"
                            >
                                <SubscribeForm/>
                                <StyledSubscribeButton show={`${show}`}
                                                       addmargin={`${true}`}
                                                       onClick={handleClose}
                                >
                                    Cancel
                                </StyledSubscribeButton>
                                <StyledSubscribeButton show={`${show}`}
                                                       addmargin={`${false}`}
                                                       className="btn"
                                                       type="submit"
                                >
                                    Subscribe
                                </StyledSubscribeButton>
                            </Form>
                        </Animated>
                    </StyledSubscribeContainer>
                }
                <Container fluid={true}>
                    <Row>
                        <Col>
                            <SoundCloudPlayer/>
                        </Col>
                    </Row>
                </Container>
            </Box>
        </>

    )
}

export default Listen