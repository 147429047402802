import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {StyledFooterContainer} from "../../styles/Styled/StyledFooter";

const Footer = () => {
    const getDate = () => {
        const date = new Date();
        return date.getFullYear();
    };

    return (
        <StyledFooterContainer fluid="true">
            <Row>
                <Col className="justify-content-center">
                    <div className="mx-auto">
                        <p className="text-center mt-3">Copyright &copy; ill.Gates {getDate()}</p>
                    </div>
                </Col>
            </Row>
        </StyledFooterContainer>
    )
}

export default Footer