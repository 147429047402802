import {createContext, memo, useEffect, useContext, useState} from "react"
import {IScreenContext, IScreenSize, ScreenProviderProps} from "../types/Screen"

const ScreenContext = createContext<IScreenContext | undefined>(undefined)

const ScreenProvider = memo(({children}: ScreenProviderProps) => {
    const [screenSize, setScreenSize] = useState<IScreenSize>({width: 0, height: 0})
    const [isMobile, setIsMobile] = useState(window.innerWidth < 700)

    useEffect(() => {
        window.addEventListener('load', updateWindowDimensions)
        window.addEventListener('resize', updateWindowDimensions)
    }, [])

    /**
     * Updates the current window dimensions
     */
    const updateWindowDimensions = () : void => {
        setIsMobile(window.innerWidth < 700)
        setScreenSize({
            width: window.innerWidth,
            height: window.innerHeight
        })
    }

    const value = {isMobile, screenSize}

    return (
        <ScreenContext.Provider value={value}>
            {children}
        </ScreenContext.Provider>
    )
})

const useScreenContext = () => {
    const context = useContext(ScreenContext)
    if (context === undefined) {
        throw new Error('useScreenContext must be used within a ScreenProvider')
    }
    return context
}

export {ScreenProvider, useScreenContext}