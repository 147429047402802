import React, {PropsWithChildren} from 'react'
import Card from 'react-bootstrap/Card'
import {StyledBoxCard, StyledSpacer} from "../../styles/Styled/StyledGeneric";
import FadeInSection from "./FadeInSection";

const Box = (props: PropsWithChildren<any>) => {
    return (
        <FadeInSection>
            <StyledBoxCard>
                <div className="text-center">
                    {props.children}
                </div>
            </StyledBoxCard>
            <StyledSpacer/>
        </FadeInSection>
    );
};

export default Box;