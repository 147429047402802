import styled from "styled-components";

type Props = {
    isVisible: boolean
}

const StyledFadeInSection = styled.section<Props>`
  width: 100%;
  opacity: ${p => p.isVisible ? 1 : 0};
  visibility: ${p => p.isVisible ? "visible" : "hidden"};
  transition: opacity 1s ease-in-out;
  will-change: opacity, visibility;
`

export {StyledFadeInSection}