import React, {useState} from 'react'
import Header from "./Header";
import {
    HeaderContainer,
    Wrap,
    HeaderContent,
    StyledNav,
    LogoContainer,
    StyledLogo,
    SocialLinksContainer,
    StyledHeaderBorder,
    StyledMenuButton,
    StyledMenuButtonContainer,
    StyledMenuToggleInput,
    StyledMobileMenu
} from "../../styles/Styled/StyledHeader";
import MenuItems from "./MenuItems";
import SocialItems from "./SocialItems";
import {useScrollContext} from "../../Context/ScrollContext";
import {useScreenContext} from "../../Context/ScreenContext";
import Logo from "./Logo";


const Nav = () => {
    const [showMobileMenu, setShowMobileMenu] = useState(false)
    const screenContext = useScreenContext()
    const scrollContext = useScrollContext()
    const showStickyHeader = scrollContext.showStickyHeader

    if (!screenContext || screenContext.isMobile === undefined) {
        return null
    }

    const getHeaderContent = () => {
        if (screenContext.isMobile) {
            return (
                <>
                    <LogoContainer isMobile={screenContext.isMobile}>
                        <Logo/>
                    </LogoContainer>
                    <StyledMenuToggleInput
                        id="menu-toggle"
                        type="checkbox"
                        onClick={() => {
                            if (showMobileMenu) {
                                setTimeout(() => {
                                    setShowMobileMenu(!showMobileMenu)
                                }, 20)
                            } else {
                                setShowMobileMenu(!showMobileMenu)
                            }

                        }}
                    />
                    <StyledMenuButtonContainer htmlFor="menu-toggle">
                        <StyledMenuButton/>
                    </StyledMenuButtonContainer>
                    <StyledMobileMenu showMobileMenu={showMobileMenu}>
                        <MenuItems/>
                    </StyledMobileMenu>
                </>
            )
        }

        return <>
            {showStickyHeader &&
                <StyledNav>
                    <MenuItems/>
                </StyledNav>
            }
            <LogoContainer isMobile={false}>
                <StyledLogo/>
            </LogoContainer>
            {showStickyHeader &&
                <SocialLinksContainer>
                    <SocialItems/>
                </SocialLinksContainer>
            }
        </>
    }

    return (
        <>
            <Header>
                <HeaderContainer id="header-container"
                                 showStickyHeader={showStickyHeader}
                >
                    <Wrap>
                        <HeaderContent showStickyHeader={showStickyHeader}>
                            {getHeaderContent()}
                        </HeaderContent>
                    </Wrap>
                </HeaderContainer>
            </Header>
            <StyledHeaderBorder showStickyHeader={showStickyHeader}/>
        </>
    )
}

export default Nav