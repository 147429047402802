import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Nav from "react-bootstrap/Nav";
import {Animated} from "react-animated-css";
import {useScreenContext} from "../../Context/ScreenContext";
import {SOCIAL_ITEMS} from "../../Constants";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const SocialItems = () => {
        const screenContext = useScreenContext()

        const socialImgStyle = {
            color: "#fff",
            width: "34px",
            fontSize: "24px",
            lineHeight: "24px"
        };


        const SocialListItem = ({item, idx}: any) => {
            if (screenContext.isMobile) {
                return (
                    <Col className={"text-center mt-3"} key={idx}>
                        <a style={{display: "inline"}}
                           href={item.link}
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                            <FontAwesomeIcon style={socialImgStyle} icon={item.icon}/>
                        </a>
                    </Col>
                )
            }

            let delay = 1000 + (idx + 1) * 200;
            return (
                <Nav.Item key={idx}>
                    <Animated animationInDuration={200}
                              animationIn={"fadeInRight"}
                              animationOut={"fadeOutRight"}
                              isVisible={true}
                              animationInDelay={delay}
                    >
                        <Nav.Link className="p-md-2 p-sm-0" href={item.link} target="_blank">
                            <FontAwesomeIcon style={socialImgStyle} icon={item.icon}/>
                        </Nav.Link>
                    </Animated>
                </Nav.Item>
            );
        };

        const socialItems = <>{SOCIAL_ITEMS.map((item, idx) => SocialListItem({item, idx}))}</>

        if (screenContext.isMobile) {
            return (
                <Container fluid={true}>
                    <Row>
                        {socialItems}
                    </Row>
                </Container>
            )
        }

        return (
            <Nav>
                {socialItems}
            </Nav>
        )
    }
;

export default SocialItems