import React from 'react'
import Hero from "../Hero/Hero";
import Listen from './Sections/Listen/Listen'
import Watch from './Sections/Watch/Watch'
import Dance from './Sections/Dance/Dance'
import Shop from './Sections/Shop/Shop'
import Learn from './Sections/Learn/Learn'
import {useScreenContext} from "../../Context/ScreenContext";
import SocialItems from "../Nav/SocialItems"
import {StyledMain} from "../../styles/Styled/StyledGeneric"

const Main = () => {
    const screenContext = useScreenContext()
    return (
        <StyledMain>
            <Hero />
            <div id="top" className="container-fluid parallax-container ">
                {screenContext.isMobile && <SocialItems/>}
                <div className="parallax-body">
                    <Shop/>
                    <Listen/>
                    {/*<Watch/>*/}
                    <Dance/>
                    {/*<Learn/>*/}
                </div>
            </div>
        </StyledMain>
    )
}

export default Main