import React from "react";
import {
    HeroWrapper,
    Section,
    StyledIFrame,
    VideoContainer,
    StyledIFrameContainer,
    VideoPlaceholderContainer
} from "../../styles/Styled/StyledHero";
import {VIDEO_URL, VIDEO_TITLE} from "../../Constants";

const Hero = () => {
    return (
        <Section>
            <div style={{
                margin: 0,
                padding: 0,
                border: 0,
                fontSize: "100%",
                font: "inherit",
                verticalAlign: "baseline"
            }}>
                <HeroWrapper/>
                <VideoContainer>
                   <VideoPlaceholderContainer />
                    <StyledIFrameContainer>
                        <StyledIFrame src={VIDEO_URL}
                                      frameBorder="0"
                                      width="426px"
                                      height="240px"
                                      allow="autoplay; fullscreen; picture-in-picture"
                                      allowFullScreen
                                      title={VIDEO_TITLE}
                        />
                    </StyledIFrameContainer>
                </VideoContainer>
            </div>
        </Section>
    )
}

export default Hero