import React from "react";
import {StyledTourScheduleContainer} from "../../../../styles/Styled/StyledDance";
import {TEXT_COLOR, ACCENT_COLOR, BLUE_COLOR} from "../../../../Constants";

const TourSchedule = () => {
    return (
        <StyledTourScheduleContainer>
            <a href="/"
               className="bit-widget-initializer"
               data-artist-name="ill.Gates"
               data-display-local-dates="false"
               data-display-past-dates="true"
               data-auto-style="false"
               data-text-color={TEXT_COLOR}
               data-link-color={BLUE_COLOR}
               data-background-color="rgba(0,0,0,0)"
               data-display-limit="15"
               data-display-start-time="true"
               data-link-text-color={BLUE_COLOR}
               data-display-lineup="true"
               data-display-play-my-city="true"
               data-separator-color="rgba(124,124,124,0.25)"
            >
            </a>
        </StyledTourScheduleContainer>
    );
};

export default TourSchedule