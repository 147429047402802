import React from 'react'
import gradientLogo from "../../images/illgates-logo-gradient-outlined.png"
import whiteLogo from "../../images/illgates-logo-fade-light.png"

import {useScrollContext} from "../../Context/ScrollContext";

const Logo = () => {
    const scrollContext = useScrollContext()
    let logo

    if (scrollContext.showStickyHeader) {
        logo = whiteLogo
    } else {
        logo = gradientLogo
    }

    return (
        <img src={logo}
             alt="ill gates logo"
        />
    )
}

export default Logo