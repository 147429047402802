import {faFacebook, faSoundcloud, faTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";
import pants from "./images/pants.jpg";

const VIDEO_URL = "https://player.vimeo.com/video/704963108?h=26dfd0dcca&muted=1&autoplay=1&loop=1&background=1&app_id=58479#t=1m13s"
const VIDEO_TITLE = "Wake Up - ill.Gates Ft. Spiderhound + Prop Anon"

const SOCIAL_ITEMS = [
    {
        link: "https://facebook.com/illgatesmusic?utm_campaign=normal-traffic&amp;utm_medium=home-page&amp;utm_source=ill-gates-media&amp;utm_content=social-footer&amp;utm_term=new-leads",
        icon: faFacebook
    },
    {
        link: "https://twitter.com/illgatesmusic?utm_campaign=normal-traffic&amp;utm_medium=home-page&amp;utm_source=ill-gates-media&amp;utm_content=social-footer&amp;utm_term=new-leads",
        icon: faTwitter
    },
    {
        link: "https://soundcloud.com/ill-gates?utm_campaign=normal-traffic&utm_medium=home-page&utm_source=ill-gates-media&utm_content=social-footer&utm_term=new-leads",
        icon: faSoundcloud
    },
    {
        link: "https://www.youtube.com/illgatesmusic?utm_campaign=normal-traffic&utm_medium=home-page&utm_source=ill-gates-media&utm_content=social-footer&utm_term=new-leads",
        icon: faYoutube
    }
];

const SHOP_ITEMS = [
    {
        title: "Shirts",
        link: "https://producerdj.com/collections/vendors?q=ill.GATES",
        imgSrc: pants
    },
    {
        title: "Pants",
        link: "https://producerdj.com/collections/vendors?q=ill.GATES",
        imgSrc: pants
    },
    {
        title: "Hoodies",
        link: "https://producerdj.com/collections/vendors?q=ill.GATES",
        imgSrc: pants
    },
    {
        title: "Accessories",
        link: "https://producerdj.com/collections/vendors?q=ill.GATES",
        imgSrc: pants
    },
    {
        title: "Hoodies Again!",
        link: "https://producerdj.com/collections/vendors?q=ill.GATES",
        imgSrc: pants
    },
];



// https://open.spotify.com/artist/4a32m0kHbxZm3V7jHpW60g?autoplay=true

const SPOTIFY_URL = "https://open.spotify.com/follow/?uri=spotify:artist:4a32m0kHbxZm3V7jHpW60g&size=detail&theme=dark"
// const SPOTIFY_URL = "https://open.spotify.com/artist/?uri=spotify:artist:4a32m0kHbxZm3V7jHpW60g&size=detail&theme=dark"
const APPLE_MUSIC_URL = "https://music.apple.com/us/artist/ill-gates/292581519?itsct=music_box_badge&amp;itscg=30200&amp;ct=artists_ill.gates&amp;app=music&amp;ls=1"
const APPLE_MUSIC_IMG = "https://tools.applemediaservices.com/api/badges/listen-on-apple-music/standard-white/en-us?size=250x83&h=b5f857bc25b758abc34e938702c5edce"
const SOUNDCLOUD_PLAYER_URL = "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1420077373&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
const YOUTUBE_URL = "https://www.youtube.com/embed/videoseries?list=PLsvTF6Tc2vlf5w-_uRfS96pQkCTWyuOin"

const SUBSCRIBE_TOKEN = "Ktiratqd4Xidgur0Idi21bqLHQdgtAaJN1BNGoKjuqSY3xbRVVRHPZYEym4arHkbifI4itUJF6zQmiI3Z4MC0g=="
const SUBSCRIBE_PARSLEY_ID = "1241"

const SHOP_CIRCLE_SIZE = "200px"

const TEXT_COLOR = "white"
const ACCENT_COLOR = "black"//"rgb(227, 51, 124)"
const BLUE_COLOR = "#43ACE2"

const SUBSCRIBE_FORM_ACTION = "https://members.producerdojo.com/forms/24287/form_submissions"

const SPACER_WIDTH = "1vh"

export {
    VIDEO_URL,
    VIDEO_TITLE,
    SOCIAL_ITEMS,
    SHOP_CIRCLE_SIZE,
    SHOP_ITEMS,
    TEXT_COLOR,
    ACCENT_COLOR,
    SPOTIFY_URL,
    APPLE_MUSIC_URL,
    APPLE_MUSIC_IMG,
    SUBSCRIBE_TOKEN,
    SUBSCRIBE_PARSLEY_ID,
    SOUNDCLOUD_PLAYER_URL,
    YOUTUBE_URL,
    SUBSCRIBE_FORM_ACTION,
    SPACER_WIDTH,
    BLUE_COLOR
}