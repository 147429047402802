import styled from "styled-components";
import {TEXT_COLOR, ACCENT_COLOR} from "../../Constants";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

type ShowProps = {
    show: boolean,
    addmargin: boolean | string
}

type ContainerProps = {
    show: boolean
}

const StyledSubscribeButton = styled(Button)<ShowProps>`
  position: relative;
  z-index: 1;
  background-color: ${ACCENT_COLOR};
  border-color: ${TEXT_COLOR};
  color: ${TEXT_COLOR};
  margin-right: ${p => (p.addmargin !== "false") ? "1.5em" : 0 };
  margin-top: 1em;
  opacity: ${p => p.show ? 1 : 0};
  letter-spacing: 1.5px;
  
  &:hover {
    background-color: ${TEXT_COLOR};
    border-color: ${ACCENT_COLOR};
    color: ${ACCENT_COLOR};
  }
`

const StyledSubscribeContainer = styled.div<ContainerProps>`
  position: relative; 
  z-index: 1;
  opacity: ${p => p.show ? 1 : () => { return setTimeout(() => 0), 10 } };
`

const StyledListenHeaderContainer = styled(Container)`
  padding-top: 2em;
  min-width: 20%;
  min-height: 20%;
  text-align: center;
  background-color: black;
  color: ${TEXT_COLOR}; 
  position: relative; 
  z-index: 10000;
`

const StyledFormRow = styled(Row)`
  letter-spacing: 2px;
  
  & input {
    letter-spacing: 2px;
    font-family: "Helvetica Neue", sans-serif;
  }
`

export {
    StyledSubscribeButton,
    StyledSubscribeContainer,
    StyledListenHeaderContainer,
    StyledFormRow
}