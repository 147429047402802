import styled from "styled-components";
import Logo from "../../Components/Nav/Logo";

type StyledHeaderProps = {
    showStickyHeader: boolean
}

type MobileMenuProps = {
    showMobileMenu: boolean
}

type LogoContainerProps = {
    isMobile: boolean
}

const StyledHeader = styled.div`
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 2000;
  vertical-align: baseline;
  text-align: center;
`

const StyledHeaderBorder = styled.div<StyledHeaderProps>`
  @keyframes shrinkBox {
    0% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
      opacity: 0;
    }
  }

  @keyframes expandBox {
    0% {
      transform: scaleX(0);
      opacity: 0;
    }
    100% {
      transform: scaleX(1);
    }
  }

  display: block;
  opacity: ${p => p.showStickyHeader ? 1 : 0};
  background: white;
  position: fixed;
  z-index: 1001;
  height: 2px;
  margin-top: 74px;
  width: 100%;
  -webkit-animation-fill-mode: forwards !important;
  -moz-animation-fill-mode: forwards !important;
  -o-animation-fill-mode: forwards !important;
  animation-fill-mode: forwards !important;
  animation: ${p => p.showStickyHeader ? "expandBox 1s ease" : "shrinkBox 1s ease"};
`

const HeaderContainer = styled.div<StyledHeaderProps>`
  @keyframes headerFade {
    from {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }


  position: ${p => p.showStickyHeader ? "fixed" : "absolute"};;
  top: ${p => p.showStickyHeader ? 0 : "auto"};;
  width: 100%;
  color: inherit;
  background-color: transparent;

  @media only screen and (min-width: 750px) {
    background-color: ${p => p.showStickyHeader ? "black" : "transparent"};
    animation: ${p => p.showStickyHeader ? "headerFade 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);" : "none"};
  }

  @media only screen and (max-width: 749px) {
    position: fixed;
    top: 0;
    background: black;
  }
`

const Wrap = styled.div`
  position: relative;
  line-height: 1;
  z-index: 99;
`

const HeaderContent = styled.div<StyledHeaderProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 12px 30px;
  z-index: 1;
  position: relative;
  background-color: transparent;
  transition: background 0.5s ease-in-out;
  animation-delay: 1s;

  @media only screen and (min-width: 750px) {
    background-color: ${p => p.showStickyHeader ? "black" : "transparent"};
  }
`

const StyledNav = styled.nav`
  padding-left: 10px;
  font-size: 24px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`

const LogoContainer = styled.div<LogoContainerProps>`
  line-height: 1;
  margin: 0 0 0 ${p => p.isMobile ? "1.27em" : 0};
  display: block;
  position: relative;
  z-index: 3;
  text-align: center;
`

const StyledLogo = styled(Logo)`
  display: block;
  max-width: 100%;
  max-height: 200px;
  height: auto;
  object-fit: contain;
`

const SocialLinksContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-right: 30px;
  font-size: 18px;
  line-height: normal;
`

const StyledMenuButtonContainer = styled.label`
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 700px) {
      display: flex;
  }
`

const StyledMenuButton = styled.div`
  display: block;
  background-color: #fff;
  position: absolute;
  height: 4px;
  width: 30px;
  transform: translateX(60px);
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;

  &:before {
    display: block;
    background-color: #fff;
    position: absolute;
    height: 4px;
    width: 30px;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 2px;
  }

  &:after {
    display: block;
    background-color: #fff;
    position: absolute;
    height: 4px;
    width: 30px;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 2px;
  }

  &:before {
    content: '';
    margin-top: -8px;
  }

  &:after {
    content: '';
    margin-top: 8px;
  }
`

const StyledMobileMenu = styled.div<MobileMenuProps>`
  display: none;
  margin: 0;
  padding: 0;
  
  @media (max-width: 700px) {
    opacity: ${p => p.showMobileMenu ? 1 : 0};
    display: flex;
    position: absolute;
    top: 0;
    margin-top: 3em;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;

    > .nav-item {
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0.5em 0;
      width: 100%;
      color: white;
      background-color: #222;
    }
    > .nav-item:not(:last-child) {
      border-bottom: 1px solid #444;
    }
  }
`

const StyledMenuToggleInput = styled.input`
  display: none;

  &:checked + ${StyledMenuButtonContainer} ${StyledMenuButton}:before {
    margin-top: 0;
    transform: rotate(405deg);
  }

  &:checked + ${StyledMenuButtonContainer} ${StyledMenuButton} {
    background: rgba(255, 255, 255, 0);
  }
  
  &:checked + ${StyledMenuButtonContainer} ${StyledMenuButton}:after {
    margin-top: 0;
    transform: rotate(-405deg);
  }


  @media (max-width: 700px) {
    ~ ${StyledMobileMenu} .nav-item {
      height: 0;
      margin: 0;
      padding: 0;
      border: 0;
      transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    &:checked ~ ${StyledMobileMenu} .nav-item {
      border: 1px solid #333;
      height: 2.5em;
      padding: 0.5em;
      transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    
    &:not(:checked) {
      display: none
    }
  }
`

export {
    StyledHeader,
    HeaderContainer,
    Wrap,
    HeaderContent,
    StyledNav,
    LogoContainer,
    StyledLogo,
    SocialLinksContainer,
    StyledHeaderBorder,
    StyledMenuButton,
    StyledMenuButtonContainer,
    StyledMenuToggleInput,
    StyledMobileMenu
}