import React from 'react';
import Box from '../../../Generic/Box';
import ShopContent from "./ShopContent";
import {StyledSectionMarker} from "../../../../styles/Styled/StyledGeneric";

const Shop = () => {
    return (
        <>
            <StyledSectionMarker id="shop"/>
            <Box>
                <ShopContent/>
            </Box>
        </>
    );
}

export default Shop