import React, {useRef} from "react"
import {StyledFadeInSection} from "../../styles/Styled/StyledFadeInSection";

const FadeInSection = ({children}) => {
    const domRef = useRef<HTMLElement>(null)

    const [isVisible, setVisible] = React.useState(false)

    React.useEffect(() => {
        if (domRef && domRef.hasOwnProperty("current") && domRef.current) {

            const observer = new IntersectionObserver(entries => {
                // In your case there's only one element to observe:
                if (entries[0].isIntersecting && domRef.current) {
                    // Not possible to set it back to false like this:
                    setVisible(true)
                    // No need to keep observing:
                    observer.unobserve(domRef.current)
                }
            })

            observer.observe(domRef.current)
            // eslint-disable-next-line react-hooks/exhaustive-deps
            return () => observer.unobserve(domRef.current as HTMLElement)
        }
    }, [])


    return <StyledFadeInSection ref={domRef} isVisible={isVisible}>{children}</StyledFadeInSection>


}

export default FadeInSection