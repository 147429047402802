import {createContext, memo, useEffect, useContext, useMemo, useState, useCallback} from "react"
import {IScrollContext, ScrollProviderProps} from "../types/Scroll"

const ScrollContext = createContext<IScrollContext | undefined>(undefined)

const ScrollProvider = memo(({children}: ScrollProviderProps) => {
    const [showStickyHeader, setShowStickyHeader] = useState(false)


    useEffect(() => {
        window.addEventListener("scroll", () => {
            setTimeout(() => {
                window.scrollY > 0 ? setShowStickyHeader(true) : setShowStickyHeader(false)
            }, 5)
        })
    })

    const value = useMemo(() => ({showStickyHeader}), [showStickyHeader])

    return (
        <ScrollContext.Provider value={value}>
            {children}
        </ScrollContext.Provider>
    )
})

const useScrollContext = () => {
    const context = useContext(ScrollContext)
    if (context === undefined) {
        throw new Error('useScrollContext must be used within a ScrollProvider')
    }
    return context
}

export {ScrollProvider, useScrollContext}