import React, {PropsWithChildren} from "react";
import {StyledHeader} from "../../styles/Styled/StyledHeader";

const Header = (props: PropsWithChildren<any>) => {
    return (
        <header>
            <StyledHeader>{props.children}</StyledHeader>
        </header>
    )
}

export default Header