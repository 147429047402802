import React from "react";
import Nav from "react-bootstrap/Nav";
import MenuItem from "./MenuItem";
import {Animated} from "react-animated-css";
import {useScreenContext} from "../../Context/ScreenContext";


const MenuItems = () => {
    const screenContext = useScreenContext()
    const menuItems = [
        {sectionId: "shop"},
        {sectionId: "listen"},
        // {sectionId: "watch"},
        {sectionId: "dance"},
        // {sectionId: "learn"}
    ];

    const itemsList = menuItems.map((item, idx) => {
        let delay = ((idx + 1) * 100);
        return (
            <Nav.Item key={idx}>
                <Animated animationInDuration={1000}
                          animationIn="fadeInRight"
                          animationOut="fadeOutRight"
                          isVisible={true}
                          className="mx-auto"
                          animationInDelay={delay}>
                    <MenuItem
                        sectionId={item.sectionId}
                        sectionLabel={item.sectionId.toUpperCase()}
                    />
                </Animated>
            </Nav.Item>
        );
    });

    const mobileItemsList = menuItems.map((item, idx) => {
        return (
            <Nav.Item key={idx} className="mx-auto" style={{fontSize: "1.5rem"}}>
                <MenuItem
                    sectionId={item.sectionId}
                    sectionLabel={item.sectionId.toUpperCase()}
                />
            </Nav.Item>
        );
    });

    if (screenContext.isMobile) {
        return <>{mobileItemsList}</>
    }

    return (
        <Nav className="mx-auto">
            {itemsList}
        </Nav>
    );
};

export default MenuItems