import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {StyledShopTitle} from "../../../../styles/Styled/StyledShop";

const ShopContent = () => {
    return (
        <Container fluid={true}>
            <Row>
                <Col>
                    <StyledShopTitle>Shop</StyledShopTitle>
                </Col>
            </Row>
            <div id='collection-component-1651722365435' />
        </Container>
    );
};

export default ShopContent