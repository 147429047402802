import React from 'react';
import Box from '../../../Generic/Box';
import TourSchedule from "./TourSchedule";
import {StyledSectionMarker, StyledSectionHeader} from "../../../../styles/Styled/StyledGeneric";

const Dance = () => {
    return (
        <div>
            <StyledSectionMarker id="dance"/>
            <Box>
                <StyledSectionHeader>
                    Tour Dates
                </StyledSectionHeader>
                <TourSchedule/>
            </Box>
        </div>
    )
}

export default Dance