import React, {useEffect} from 'react'
import Nav from './Components/Nav/Nav'
import Main from "./Components/Main/Main";
import Footer from './Components/Footer/Footer'
import {ScrollProvider} from "./Context/ScrollContext";

const App: React.FC = () => {
    return (
        <>
            <ScrollProvider>
                <Nav />
            </ScrollProvider>
            <Main />
            <Footer />
        </>
    )
}
export default App
