import React from 'react'
import {SOUNDCLOUD_PLAYER_URL} from "../../../../Constants";

const SoundCloudPlayer = () => {
        return (
            <iframe width="100%"
                    height="500px"
                    scrolling="no"
                    frameBorder="no"
                    title="SoundCloud Player"
                    allow="autoplay"
                    src={SOUNDCLOUD_PLAYER_URL}
                    className={"mt-3"}
            />
        )
};

export default SoundCloudPlayer;