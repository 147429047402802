import styled from "styled-components"
import {SHOP_CIRCLE_SIZE, TEXT_COLOR} from "../../Constants";

const StyledShop = styled.div`
  height: ${() => SHOP_CIRCLE_SIZE};
  width: ${() => SHOP_CIRCLE_SIZE};
  border-radius: 50%;
  border: 2px solid #fec200;
  overflow: hidden;
  margin: auto;
`

const StyledShopImage = styled.img`
  height: ${() => SHOP_CIRCLE_SIZE};
  width: ${() => SHOP_CIRCLE_SIZE};
`

const StyledItemTitle = styled.h3`
  color: ${() => TEXT_COLOR};
`

const StyledShopTitle = styled.h1`
  color: ${() => TEXT_COLOR};
`

export {StyledShop, StyledShopImage, StyledItemTitle, StyledShopTitle}