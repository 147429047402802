import styled from "styled-components";
import {TEXT_COLOR, SPACER_WIDTH} from "../../Constants";
import Card from "react-bootstrap/Card";

const StyledSectionMarker = styled.div`
  padding: 2%;
`

const StyledSectionHeader = styled.h1`
  color: ${TEXT_COLOR}
`

const StyledSectionH3 = styled.h3`
  color: ${TEXT_COLOR}
`

const StyledBoxCard = styled.div`
  width: 100%;
  //border: 2px solid ${TEXT_COLOR};
  overflow: hidden;
`

const StyledSpacer = styled.div`
  min-height: ${SPACER_WIDTH};
`

const StyledMain = styled.main`
  @media only screen and (orientation: portrait) {
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }

  @media only screen and (max-width: 700px) {
    @media only screen and (orientation: landscape) {
      width: 90%;      /* Shrink a little more to avoid the notch. */
      text-align: center;
      margin: 0 auto;
    }
  }
`

export {
    StyledSectionMarker,
    StyledSectionHeader,
    StyledSectionH3,
    StyledBoxCard,
    StyledSpacer,
    StyledMain
}