import Form from "react-bootstrap/Form"
import {SUBSCRIBE_PARSLEY_ID, SUBSCRIBE_TOKEN} from "../../../../../Constants"
import {StyledFormRow} from "../../../../../styles/Styled/StyledListen"
import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const SubscribeForm = () => {

    return (
        <div>
            <ul className="parsley-errors-list"
                id="parsley-id-1241">
            </ul>

            <Form.Control name="utf8"
                          type="hidden"
                          value="&#x2713;"
            />

            <Form.Control type="hidden"
                          name="authenticity_token"
                          value={SUBSCRIBE_TOKEN}
            />

            <Form.Control type="text"
                          name="website_url"
                          auto-fill="off"
                          placeholder="Skip this field"
                          style={{display: "none"}}
                          data-parsley-id={SUBSCRIBE_PARSLEY_ID}
            />

            <Form.Control type="hidden"
                          name="kjb_fk_checksum"
                          auto-fill="off"
                          value=""
            />

            <Form.Control type="hidden"
                          name="thank_you_url"
                          value=""
            />

            <Container>
                <StyledFormRow className={"justify-content-md-center"}>
                    <Col md={3}>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="First Name"
                                // @ts-ignore
                                controlid="formBasicPassword"
                                name="form_submission[name]"
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group>
                            <Form.Label>Email address</Form.Label>

                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                data-parsley-remote="true"
                                data-parsley-remote-validator="kjb_email_validator"
                                data-parsley-remote-message="false"
                                // @ts-ignore
                                controlid="formBasicEmail"
                                name="form_submission[email]"
                                required
                            />
                        </Form.Group>
                    </Col>
                </StyledFormRow>
                <StyledFormRow className={"justify-content-md-center"}>
                    <Col>
                        <Form.Text className="text-muted" style={{fontSize: "1.23rem"}}>
                            We'll never share your email with anyone else.
                        </Form.Text>
                    </Col>
                </StyledFormRow>
            </Container>
        </div>
    );
}

export default SubscribeForm
